import React from 'react';
import { PageHeader, Button, Table, Space, Popconfirm } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { accountCreateRoute, accountEditRoute } from '../router/routes';

const AccountsContainer = () => {
  const [{ data: storedData }, refetchAccounts] = useAxiosAuth({
    url: `/accounts`,
    method: 'GET',
  });

  const [, deleteAccount] = useAxiosAuth(
    {
      method: 'DELETE',
    },
    { manual: true },
  );

  const onDeleteAccount = (id) => {
    deleteAccount({
      url: `${process.env.REACT_APP_SERVER_URL}/accounts/${id}`,
    })
      .then((res) => {
        refetchAccounts();
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  const columns = [
    {
      title: 'Exchange',
      render: (to, record) => record.exchange.name,
      key: 'exchange',
    },
    {
      title: 'Name',
      render: (to, record) => record.name,
      key: 'name',
    },
    {
      title: 'Description',
      render: (to, record) => record.description,
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button key="1" type="primary" href={accountEditRoute.replace(':id', record.id)}>
            Edit
          </Button>
          <Popconfirm
            placement="top"
            title="Are you sure to delete this element?"
            onConfirm={() => onDeleteAccount(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Account list"
        subTitle="All personal Accounts from various exchanges"
        extra={[
          <Button key="1" type="primary" href={accountCreateRoute}>
            New
          </Button>,
        ]}
      />
      <Table style={{ overflow: 'auto' }} columns={columns} dataSource={storedData} />
    </div>
  );
};

export default AccountsContainer;
