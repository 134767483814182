import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { PageHeader, Typography, Row, Col, Spin, Card, Statistic } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';

const { Title } = Typography;

const AccountBalancesContainer = () => {
  const [{ data: accountsData, loading }] = useAxiosAuth({
    url: `/accounts/get-all-balances`,
    method: 'GET',
  });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Account balances"
        subTitle="Personal wallets with crypto distribution chart"
      />
      <Title level={3} style={{ margin: 20 }}>
        Total balance: {formatter.format(accountsData?.total ?? 0)}
      </Title>
      <Row gutter={[24, 24]} style={{ padding: 20 }}>
        {loading ? (
          <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin size="large" />
          </Col>
        ) : (
          accountsData?.balances
            ?.sort((balA, balB) => balB.total - balA.total)
            .map((balance, i) => {
              const activeTradebots = balance.account?.tradebots?.filter((t) => t.isRunning && t.useRealMoney) ?? [];
              return (
                <Col xs={24} md={24} lg={24} xl={12} key={i}>
                  <Card>
                    <div>
                      <Statistic title={balance.account.name} value={formatter.format(balance?.total)} />
                    </div>
                    <div>
                      {activeTradebots.length ? <b>ACTIVE TRADEBOTS:</b> : null}
                      {activeTradebots?.map((tr) => (
                        <div>
                          {`#${tr.id} ${tr.asset}/${tr.currency} - ${tr.strategyName} ${
                            tr.usePartialBalance ? 'PARTIAL' : 'FULL'
                          } - (${tr.assetBalance?.toFixed(4)} ${tr.asset} / ${(
                            tr.currencyBalance + tr.parkedCurrency
                          )?.toFixed(4)} ${tr.currency})`}
                        </div>
                      ))}
                    </div>
                    <div className="mobile-none" style={{ height: 600, marginBottom: 50, minWidth: 500 }}>
                      <ResponsivePie
                        data={balance.coins?.map((coin) => ({
                          id: coin.coin,
                          label: coin.coin,
                          value: coin.value?.toFixed(2),
                          color: coin.coin === 'BTC' ? 'orange' : '#' + (((1 << 24) * Math.random()) | 0).toString(16),
                        }))}
                        margin={{ top: 50, right: 150, bottom: 50, left: 50 }}
                        padAngle={0.7}
                        innerRadius={0.5}
                        cornerRadius={3}
                        valueFormat={(value) => formatter.format(value)}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsLinkStrokeWidth={3}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkColor={{ from: 'color' }}
                        sliceLabelsSkipAngle={10}
                        sliceLabelsTextColor="white"
                        theme={{
                          fontSize: 15,
                          fontWeight: 'bold',
                        }}
                        legends={[
                          {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 130,
                            translateY: 40,
                            itemsSpacing: 20,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemTextColor: '#000',
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  </Card>
                </Col>
              );
            })
        )}
      </Row>
    </div>
  );
};

export default AccountBalancesContainer;
