import React from 'react';
import './App.scss';
import 'antd/dist/antd.css';
import AppRouter from './router/router';
import { AuthProvider } from './context/AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
};

export default App;
