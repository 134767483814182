import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { PageHeader, Collapse, Form, Input, Button, Select, DatePicker, Table } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';

const { Panel } = Collapse;
const { Option } = Select;

const StoredDataContainer = () => {
  const [, executeFetchData] = useAxiosAuth(
    {
      url: `/historical-data/fetch-data`,
      method: 'POST',
    },
    { manual: true },
  );
  const [{ data: storedData }, refetchData] = useAxiosAuth({
    url: `/historical-data/get-historical-data`,
    method: 'GET',
  });

  const [{ data: exchanges }] = useAxiosAuth({
    url: `/exchanges`,
    method: 'GET',
  });
  console.log('storedData', storedData);
  console.log('exchanges', exchanges);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = (values) => {
    console.log('Finish:', values);
    executeFetchData({
      data: {
        ...values,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const [, deleteHistoricalData] = useAxiosAuth(
    {
      url: `/historical-data`,
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const onDeleteHistoricalData = (data) => {
    const canStop = window.confirm('Are you sure you want to delete this candles?');
    if (canStop) {
      deleteHistoricalData({
        url: `${process.env.REACT_APP_SERVER_URL}/historical-data/delete`,
        data: {
          exchangeId: data.exchangeId,
          asset: data.asset,
          currency: data.currency,
        },
      }).then(() => {
        refetchData();
      });
    }
  };

  const initialValues = {
    exchangeId: null,
    startDate: moment(),
    endDate: moment(),
    asset: null,
    currency: '',
  };

  const columns = [
    {
      title: 'Exchange',
      render: (to, record) => record.exchange.name,
      key: 'exchange.name',
    },
    {
      title: 'Asset',
      key: 'asset',
      dataIndex: 'asset',
    },
    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency',
    },
    {
      title: 'Start',
      render: (to, record) => moment(record.begin).format('YYYY-MM-DD HH:mm'),
      key: 'begin',
    },
    {
      title: 'End',
      render: (to, record) => moment(record.end).format('YYYY-MM-DD HH:mm'),
      key: 'end',
    },
    {
      title: 'Duration',
      render: (to, record) => {
        const diff = moment(record.end).diff(moment(record.begin));
        return moment.duration(diff).humanize();
      },
      key: 'duration',
    },
    {
      title: 'Actions',
      render: (to, record) => (
        <Button disabled={record.isRunning} type="danger" onClick={() => onDeleteHistoricalData(record)}>
          Delete
        </Button>
      ),
      key: 'actions',
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Stored data"
        subTitle="Stored asset/currency data from the exchanges"
      />
      <Collapse style={{ marginBottom: 40 }}>
        <Panel header="New import" key="1">
          <Form form={form} name="new_import" layout="inline" initialValues={initialValues} onFinish={onFinish}>
            <Form.Item name="exchangeId" rules={[{ required: true, message: 'Please select an exchange!' }]}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select exchange"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {exchanges?.map((exc) => (
                  <Option key={exc.id} value={exc.id}>
                    {exc.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="asset" rules={[{ required: true, message: 'Please input asset!' }]}>
              <Input type="text" placeholder="Asset" />
            </Form.Item>
            <Form.Item name="currency" rules={[{ required: true, message: 'Please input currency!' }]}>
              <Input type="text" placeholder="Currency" />
            </Form.Item>
            <Form.Item name="startDate" rules={[{ required: true, message: 'Please input startDate!' }]}>
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>
            <Form.Item name="endDate" rules={[{ required: true, message: 'Please input endDate!' }]}>
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                >
                  Start
                </Button>
              )}
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
      <Table style={{ overflow: 'auto' }} columns={columns} dataSource={storedData} />
    </div>
  );
};

export default StoredDataContainer;
