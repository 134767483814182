import React, { useEffect, useState } from 'react';
import { PageHeader, Button, Table, Space, Row, Col, Card, Input, Form, Modal, message, Popconfirm } from 'antd';
import * as ethers from 'ethers';
import * as moment from 'moment';
import AES from 'crypto-js/aes';
import * as cryptoJs from 'crypto-js';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import useAxiosAuth from '../hooks/useAxiosAuth';
import useViewport from '../hooks/useViewport';
import styled from 'styled-components';

const CodeString = styled.code`
  margin-left: 10px;
  background: lightgrey;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
`;

const PersonalWalletsContainer = () => {
  const [newWallet, setNewWallet] = useState(null);
  const [showDecryptModal, setShowDecryptModal] = useState(false);
  const [cryptedWallet, setCryptedWallet] = useState(null);
  const [decryptedWallet, setDecryptedWallet] = useState(null);
  const [password, setPassword] = useState('');
  const [decryptPassword, setDecryptPassword] = useState('');
  const [width] = useViewport();

  const [{ data: personalWallets, loading }, refetchPersonalWallets] = useAxiosAuth({
    url: `/personal-wallets`,
    method: 'GET',
  });

  const [, savePersonalWallet] = useAxiosAuth(
    {
      url: `/personal-wallets`,
      method: 'POST',
    },
    { manual: true },
  );

  const [form] = Form.useForm();

  const [, deleteWallet] = useAxiosAuth(
    {
      method: 'DELETE',
    },
    { manual: true },
  );

  const onDeleteWallet = (id) => {
    deleteWallet({
      url: `${process.env.REACT_APP_SERVER_URL}/personal-wallets/${id}`,
    })
      .then((res) => {
        refetchPersonalWallets();
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  const onFinish = (values) => {
    savePersonalWallet({
      data: {
        name: values.name,
        address: cryptedWallet.address.toString(),
        privateKey: cryptedWallet.privateKey.toString(),
        mnemonic: cryptedWallet.mnemonic.toString(),
      },
    })
      .then((res) => {
        setNewWallet(null);
        setCryptedWallet(null);
        form.resetFields();
        refetchPersonalWallets();
      })
      .catch((err) => console.log(err));
  };

  const refreshEncryption = () => {
    if (password && newWallet?.address && newWallet?.privateKey && newWallet?.mnemonic) {
      setCryptedWallet({
        privateKey: AES.encrypt(newWallet.privateKey, password),
        address: AES.encrypt(newWallet.address, password),
        mnemonic: AES.encrypt(newWallet.mnemonic, password),
      });
    }
    setDecryptedWallet(null);
  };

  useEffect(() => {
    refreshEncryption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, newWallet]);

  useEffect(() => {
    if (!showDecryptModal) {
      setDecryptPassword('');
      setDecryptedWallet(null);
    }
  }, [showDecryptModal]);

  const onCreateWallet = async () => {
    const wallet = ethers.Wallet.createRandom();
    setNewWallet({
      address: wallet.address,
      privateKey: wallet.privateKey,
      mnemonic: wallet.mnemonic.phrase,
    });
    form.setFieldsValue({
      address: wallet.address,
      privateKey: wallet.privateKey,
      mnemonic: wallet.mnemonic.phrase,
    });
  };

  const onDecrypt = () => {
    if (cryptedWallet && cryptedWallet?.address) {
      try {
        const address = AES.decrypt(cryptedWallet.address, decryptPassword);
        const privateKey = AES.decrypt(cryptedWallet.privateKey, decryptPassword);
        const mnemonic = AES.decrypt(cryptedWallet.mnemonic, decryptPassword);
        setDecryptedWallet({
          address: address.toString(cryptoJs.enc.Utf8),
          privateKey: privateKey.toString(cryptoJs.enc.Utf8),
          mnemonic: mnemonic.toString(cryptoJs.enc.Utf8),
        });
        setDecryptPassword(null);
      } catch (err) {
        console.log('🚀 !!! ~ err', err);
        alert('Maybe the password is incorrect');
      }
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    message.info('Copied to clipboard');
  };

  const columns = [
    {
      title: '#',
      render: (to, record) => record.id,
      key: 'id',
    },
    {
      title: 'Name',
      render: (to, record) => record.name,
      key: 'name',
    },
    {
      title: 'Created at',
      render: (to, record) => moment(record.createdAt).format('DD-MM-YYYY HH:mm'),
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setCryptedWallet({
                address: record.address,
                privateKey: record.privateKey,
                mnemonic: record.mnemonic,
              });
              setShowDecryptModal(true);
            }}
          >
            Decrypt
          </Button>
          <Popconfirm
            placement="top"
            title="Are you sure to delete this element?"
            onConfirm={() => onDeleteWallet(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Personal Wallets"
        subTitle="All personal crypted Wallets"
        extra={[
          <Button key="1" type="primary" onClick={onCreateWallet}>
            Generate
          </Button>,
        ]}
      />
      <Row>
        <Col span={24}>
          <Card title="New personal wallet">
            <Form form={form} name="new_personal_wallet" onFinish={onFinish} onChange={() => refreshEncryption()}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
                <Input type="text" autoComplete={false} placeholder="Name" />
              </Form.Item>
              <Form.Item
                label="Public address"
                name="address"
                rules={[{ required: true, message: 'Please input address!' }]}
              >
                <Input
                  type="text"
                  placeholder="Address"
                  value={newWallet?.address || ''}
                  onChange={(e) => {
                    setNewWallet({
                      ...newWallet,
                      address: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Private key"
                name="privateKey"
                rules={[{ required: true, message: 'Please input privateKey!' }]}
              >
                <Input
                  type="text"
                  placeholder="Private key"
                  value={newWallet?.privateKey || ''}
                  onChange={(e) => {
                    setNewWallet({
                      ...newWallet,
                      privateKey: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Mnemonic phrase"
                name="mnemonic"
                rules={[{ required: true, message: 'Please input mnemonic phrase!' }]}
              >
                <Input
                  type="text"
                  placeholder="Mnemonic phrase"
                  value={newWallet?.mnemonic || ''}
                  onChange={(e) => {
                    setNewWallet({
                      ...newWallet,
                      mnemonic: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Col span={6}>
                <Form.Item
                  label="Encryption password"
                  name="password"
                  rules={[{ required: true, message: 'Please input password to encrypt wallet!' }]}
                >
                  <Input.Password
                    iconRender={(showPassword) => (showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </Form>

            <Col span={24}>
              <pre>Encrypted Address: {cryptedWallet?.address.toString()}</pre>
              <pre>Encrypted Private: {cryptedWallet?.privateKey.toString()}</pre>
              <pre>Encrypted Mnemonic: {cryptedWallet?.mnemonic.toString()}</pre>
              <Button type="primary" onClick={onDecrypt}>
                Decrypt
              </Button>
            </Col>
          </Card>
        </Col>
      </Row>
      <Table style={{ overflow: 'auto' }} columns={columns} loading={loading} dataSource={personalWallets} />
      <Modal
        title="Decrypt wallet"
        visible={showDecryptModal}
        onOk={onDecrypt}
        onCancel={() => {
          setShowDecryptModal(false);
        }}
        width={width > 700 && '50%'}
      >
        <Col span={24}>
          <label>Insert password</label>
          <Input.Password
            iconRender={(showPassword) => (showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            value={decryptPassword || ''}
            onChange={(e) => setDecryptPassword(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p style={{ marginTop: 20 }}>
            Address:{' '}
            {decryptedWallet?.address && (
              <CodeString
                onClick={() => {
                  copyToClipboard(decryptedWallet?.address);
                }}
              >
                {decryptedWallet?.address}
              </CodeString>
            )}
          </p>
          <p>
            Private key:{' '}
            {decryptedWallet?.privateKey && (
              <CodeString
                onClick={() => {
                  copyToClipboard(decryptedWallet?.privateKey);
                }}
              >
                {decryptedWallet?.privateKey}
              </CodeString>
            )}
          </p>
          <p>
            Mnemonic:{' '}
            {decryptedWallet?.mnemonic && (
              <CodeString
                onClick={() => {
                  copyToClipboard(decryptedWallet?.mnemonic);
                }}
              >
                {decryptedWallet?.mnemonic}
              </CodeString>
            )}
          </p>
        </Col>
      </Modal>
    </div>
  );
};

export default PersonalWalletsContainer;
