import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { backtestRoute } from '../router/routes';
import { useLocation, Link } from 'react-router-dom';
import { PageHeader, Form, Input, Button, Select, Table, Card, Row, Col, Space, Checkbox, Popover, Tabs } from 'antd';
import { InfoCircleOutlined, BugOutlined } from '@ant-design/icons';
import { Dot } from '../components/Dot';
import useAxiosAuth from '../hooks/useAxiosAuth';

const { Option } = Select;
const { TabPane } = Tabs;

const TradebotContainer = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const exchangeId = Number(params.get('exchangeId')) || null;
  const asset = params.get('asset') || null;
  const currency = params.get('currency') || null;
  const strategyName = params.get('strategyName') || null;
  const candleSize = Number(params.get('candleSize')) || null;
  const warmupPeriod = Number(params.get('warmupPeriod')) || null;
  const strategyParamsQuery = JSON.parse(params.get('strategyParams')) || null;
  const startDate = moment(params.get('startDate')) || null;
  const endDate = moment(params.get('endDate')) || null;

  const initialValues = {
    exchangeId,
    strategyName,
    startDate,
    endDate,
    asset,
    currency,
    candleSize,
    useRealMoney: false,
    usePartialBalance: false,
    warmupPeriod,
    assetBalance: 0,
    currencyBalance: 1000,
    feeMode: 'MAKER',
    makerFee: null,
    takerFee: null,
    ...strategyParamsQuery,
  };

  const [form] = Form.useForm();
  const [formWallet] = Form.useForm();
  const [, forceUpdate] = useState();
  const [strategyParams, setStrategyParams] = useState(null);
  const [trades, setTrades] = useState([]);

  const [{ data: tradebots, loading: isLoadingTradebots }, refetchTradebots] = useAxiosAuth({
    url: `/tradebots`,
    method: 'GET',
  });

  const [{ data: strategies }] = useAxiosAuth({
    url: `/backtester/strategies`,
    method: 'GET',
  });

  const [{ data: accounts }] = useAxiosAuth({
    url: `/accounts`,
    method: 'GET',
  });

  const [, createTradebot] = useAxiosAuth(
    {
      url: `/tradebots`,
      method: 'POST',
    },
    { manual: true },
  );

  const [, updateTradebot] = useAxiosAuth(
    {
      url: `/tradebots/:id`,
      method: 'PATCH',
    },
    {
      manual: true,
    },
  );
  const [, deleteTradebot] = useAxiosAuth(
    {
      url: `/tradebots/:id`,
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const [{ data: wallets }] = useAxiosAuth({
    url: `/wallets`,
    method: 'GET',
  });

  const [{ data: marketWatchers }] = useAxiosAuth({
    url: `/tradebots/market-watchers`,
    method: 'GET',
  });

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
    form.setFieldsValue({
      ...initialValues,
    });
    formWallet.setFieldsValue({
      ...initialValues,
    });
    if (strategyName) {
      setStrategyParams(strategyParamsQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    console.log('onFinish -> values', values);
    createTradebot({
      data: {
        ...values,
        rpc: null,
        chainId: null,
        walletId: null,
        marketWatcherId: null,
        minimumBaseCoinBalance: null,
        rechargeQtyBaseCoinBalance: null,
        gasLimit: null,
        currencyBalance: values.initialCurrency,
        assetBalance: values.initialAsset,
        candleSize: Number(values.candleSize),
        warmupPeriod: Number(values.warmupPeriod),
      },
    })
      .then((res) => {
        refetchTradebots();
      })
      .catch((err) => {
        console.log(err.response);
        const data = err.response?.data;
        alert(`Error: ${data?.error} \n${JSON.stringify(data?.message) ?? ''}`);
      });
  };

  const onFinishWallet = (values) => {
    console.log('onFinishWallet -> values', values);
    createTradebot({
      data: {
        ...values,
        accountId: null,
        currencyBalance: values.initialCurrency,
        assetBalance: values.initialAsset,
        candleSize: Number(values.candleSize),
        warmupPeriod: Number(values.warmupPeriod),
      },
    })
      .then((res) => {
        refetchTradebots();
      })
      .catch((err) => {
        console.log(err.response);
        const data = err.response?.data;
        alert(`Error: ${data?.error} \n${JSON.stringify(data?.message) ?? ''}`);
      });
  };

  const onStopTradebot = (tradebot) => {
    const canStop = window.confirm('Are you sure you want to stop this tradebot?');
    if (canStop) {
      updateTradebot({
        url: `${process.env.REACT_APP_SERVER_URL}/tradebots/${tradebot.id}`,
        data: {
          isRunning: false,
          PID: null,
        },
      }).then(() => {
        refetchTradebots();
      });
    }
  };

  const onPlayTradebot = (tradebot) => {
    const canStart = window.confirm('Are you sure you want to start this tradebot?');
    if (canStart) {
      updateTradebot({
        url: `${process.env.REACT_APP_SERVER_URL}/tradebots/${tradebot.id}`,
        data: {
          isRunning: true,
        },
      }).then(() => {
        refetchTradebots();
      });
    }
  };

  const onDeleteTradebot = (tradebot) => {
    const canStop = window.confirm('Are you sure you want to delete this tradebot?');
    if (canStop) {
      deleteTradebot({
        url: `${process.env.REACT_APP_SERVER_URL}/tradebots/${tradebot.id}`,
        data: {
          isRunning: false,
        },
      }).then(() => {
        refetchTradebots();
      });
    }
  };

  const columns = [
    {
      title: 'ID',
      key: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      render: (to, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <Dot size={10} color={record.isRunning ? 'green' : 'red'} />
            {record.id}
          </div>
        );
      },
    },
    {
      title: 'Account / Wallet',
      render: (to, record) => [
        record.useRealMoney === false && <BugOutlined key="iconBug" />,
        <span key="account">
          &nbsp;{record.account ? record.account?.exchange?.name : 'WALLET'} -{' '}
          {record.account?.name ?? record.wallet?.name}
        </span>,
      ],
      key: 'account.name',
    },
    {
      title: 'Pair',
      key: 'pair',
      render: (to, record) => <b>{`${record.candleSize}m ${record.asset}/${record.currency}`}</b>,
    },
    {
      title: 'Strategy',
      key: 'strategyName',
      render: (to, record) => {
        const popupContent = <pre>{JSON.stringify(record.strategyParams, null, 2)}</pre>;
        return (
          <Popover content={popupContent} title={record.strategyName} trigger="click">
            <Button>{record.strategyName}</Button>
          </Popover>
        );
      },
      sorter: (a, b) => ('' + a.strategyName).localeCompare(b.strategyName),
    },
    {
      title: 'Actions',
      render: (to, record) => {
        const queryString = {
          asset: record.asset,
          currency: record.currency,
          strategyParams: JSON.stringify(record.strategyParams),
          startDate: record.startDate,
          endDate: record.endDate,
          strategyName: record.strategyName,
          exchangeId: record.exchangeId,
          candleSize: record.candleSize,
          warmupPeriod: record.warmupPeriod,
        };
        const popupContent = (
          <pre>
            TelegramToken: {record.telegramToken}
            <br />
            PID:{record.PID}
            <br />
            SUBSCRIBERS:{JSON.stringify(record.subscribers)}
          </pre>
        );
        return (
          <Space>
            <Popover content={popupContent} title="Info" trigger="click">
              <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <InfoCircleOutlined />
              </Button>
            </Popover>
            <Button type="default" onClick={() => setTrades(record.trades)}>
              Select
            </Button>
            <Link to={`${backtestRoute}?${new URLSearchParams(queryString).toString()}`}>
              <Button type="default">Run</Button>
            </Link>
            {record.isRunning ? (
              <Button disabled={!record.isRunning} danger onClick={() => onStopTradebot(record)}>
                Stop
              </Button>
            ) : (
              <>
                <Button disabled={record.isRunning} danger onClick={() => onPlayTradebot(record)}>
                  Play
                </Button>
                <Button disabled={record.isRunning} type="danger" onClick={() => onDeleteTradebot(record)}>
                  Delete
                </Button>
              </>
            )}
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  const tradesColumns = [
    {
      title: '#',
      sorter: (a, b) => a.id - b.id,
      render: (to, record) => record.id,
      key: 'id',
    },
    {
      title: 'Buy price',
      sorter: (a, b) => a.buyPrice - b.buyPrice,
      render: (to, record) => record.buyPrice && record.buyPrice.toFixed(4),
      key: 'buyPrice',
    },
    {
      title: 'Sell price',
      key: 'sellPrice',
      sorter: (a, b) => a.sellPrice - b.sellPrice,
      render: (to, record) => record.sellPrice && record.sellPrice.toFixed(4),
    },
    {
      title: 'buyDate',
      sorter: (a, b) => ('' + a.buyDate).localeCompare(b.buyDate),
      render: (to, record) => (record.buyDate ? moment(record.buyDate).format('YYYY-MM-DD HH:mm') : '-'),
      key: 'buyDate',
    },
    {
      title: 'sellDate',
      sorter: (a, b) => ('' + a.sellDate).localeCompare(b.sellDate),
      render: (to, record) => (record.sellDate ? moment(record.sellDate).format('YYYY-MM-DD HH:mm') : '-'),
      key: 'sellDate',
    },

    {
      title: 'Entry balance',
      render: (to, record) => record.entryBalance && record.entryBalance.toFixed(4),
      key: 'entryBalance',
    },
    {
      title: 'Exit balance',
      key: 'exitBalance',
      render: (to, record) => record.exitBalance && record.exitBalance.toFixed(4),
    },
    {
      title: 'comment',
      sorter: (a, b) => a.comment - b.comment,
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'change',
      sorter: (a, b) => a.change - b.change,
      render: (to, record) => (
        <span style={{ color: record.change < 0 ? '#cf1322' : '#3f8600' }}>
          {Number(record?.change).toFixed(2) + '%' ?? '--'}
        </span>
      ),
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="Tradebots" subTitle="Live market tradebots" />
      <Card title="New tradebot" style={{ marginTop: 24 }}>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            form.resetFields();
            formWallet.resetFields();
          }}
        >
          <TabPane tab="Exchange tradebot" key="1">
            <Form form={form} name="newTradebot" layout="vertical" initialValues={initialValues} onFinish={onFinish}>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="accountId"
                    label="Account"
                    rules={[{ required: true, message: 'Please select an account!' }]}
                  >
                    <Select placeholder="Select account">
                      {accounts?.map((acc) => (
                        <Option key={acc.id} value={acc.id}>
                          {acc.exchange?.name} - {acc.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="asset" label="Asset" rules={[{ required: true, message: 'Please input asset!' }]}>
                    <Input type="text" placeholder="Asset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please input currency!' }]}
                  >
                    <Input type="text" placeholder="Currency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Strategy"
                    name="strategyName"
                    rules={[{ required: true, message: 'Please select a strategy!' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select strategy"
                      optionFilterProp="children"
                      onChange={(value) => {
                        setStrategyParams(strategies.find((str) => str.name === value)?.defaultParams);
                      }}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {strategies?.map((str) => (
                        <Option key={str.id} value={str.name}>
                          {str.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Candle Size in minutes"
                    name="candleSize"
                    rules={[{ required: true, message: `Please input candleSize!` }]}
                  >
                    <Input type="text" placeholder="candleSize" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="warmup in candles"
                    name="warmupPeriod"
                    rules={[{ required: true, message: `Please input warmupPeriod!` }]}
                  >
                    <Input type="text" placeholder="warmupPeriod" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}></Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="useRealMoney" valuePropName="checked">
                    <Checkbox>Use real money!</Checkbox>
                  </Form.Item>
                  <Form.Item name="usePartialBalance" valuePropName="checked">
                    <Checkbox>Use partial balance</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="initial asset balance" name="initialAsset" rules={[{ required: false }]}>
                    <Input type="text" placeholder="initialAsset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="initial currency balance" name="initialCurrency" rules={[{ required: false }]}>
                    <Input type="text" placeholder="initialCurrency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Telegram token" name="telegramToken" rules={[{ required: false }]}>
                    <Input type="text" placeholder="telegramToken" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Telegram password" name="telegramPassword" rules={[{ required: false }]}>
                    <Input type="text" placeholder="telegramPassword" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                {strategyParams &&
                  Object.keys(strategyParams).map((paramKey, i) => (
                    <Col key={i} xs={24} md={12} lg={6}>
                      <Form.Item
                        initialValue={strategyParams[paramKey]}
                        label={paramKey}
                        name={['strategyParams', paramKey]}
                        rules={[{ required: true, message: `Please input ${paramKey}!` }]}
                      >
                        <Input type="text" placeholder={paramKey} />
                      </Form.Item>
                    </Col>
                  ))}
              </Row>

              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Start
                  </Button>
                )}
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Wallet tradebot" key="2">
            <Form
              form={formWallet}
              name="newWalletTradebot"
              layout="vertical"
              initialValues={initialValues}
              onFinish={onFinishWallet}
            >
              <Row gutter={24}>
                <Col xs={24} md={6} lg={6}>
                  <Form.Item
                    name="walletId"
                    label="Wallet"
                    rules={[{ required: true, message: 'Please select a wallet!' }]}
                  >
                    <Select placeholder="Select wallet">
                      {wallets?.map((wall) => (
                        <Option key={wall.id} value={wall.id}>
                          {wall.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} lg={6}>
                  <Form.Item
                    name="marketWatcherId"
                    label="Market Watcher"
                    rules={[{ required: true, message: 'Please select a market watcher!' }]}
                  >
                    <Select placeholder="Select mw">
                      {marketWatchers?.map((mw) => (
                        <Option key={mw.id} value={mw.id}>
                          {mw.exchange.slug} {mw.asset}/{mw.currency}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="asset"
                    label="Asset (make sure is approved)"
                    rules={[{ required: true, message: 'Please input asset!' }]}
                  >
                    <Input type="text" placeholder="Asset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="currency"
                    label="Currency (make sure is approved)"
                    rules={[{ required: true, message: 'Please input currency!' }]}
                  >
                    <Input type="text" placeholder="Currency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="minimumBaseCoinBalance"
                    label="MinimumBaseCoinBalance (ex BNB for gas)"
                    rules={[{ required: true, message: 'Please input!' }]}
                  >
                    <Input type="string" placeholder="minimumBaseCoinBalance" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="rechargeQtyBaseCoinBalance"
                    label="Recharge Qty Base Coin Balance (ex BNB to buy)"
                    rules={[{ required: true, message: 'Please input!' }]}
                  >
                    <Input type="string" placeholder="rechargeQtyBaseCoinBalance" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="gasLimit"
                    label="Gas limit for transactions"
                    rules={[{ required: true, message: 'Please input!' }]}
                  >
                    <Input type="string" placeholder="gasLimit" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="chainId"
                    label="Select chainId"
                    rules={[{ required: true, message: 'Please select a chainId!' }]}
                  >
                    <Select placeholder="Select chainId">
                      <Option value={1}>ETHEREUM 1</Option>
                      <Option value={10}>OPTIMISM 10</Option>
                      <Option value={56}>BSC 56</Option>
                      <Option value={137}>POLYGON 137</Option>
                      <Option value={42161}>ARBITRUM 42161</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="rpc" label="RPC" rules={[{ required: true, message: 'Please input rpc!' }]}>
                    <Input type="string" placeholder="rpc" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Strategy"
                    name="strategyName"
                    rules={[{ required: true, message: 'Please select a strategy!' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select strategy"
                      optionFilterProp="children"
                      onChange={(value) => {
                        setStrategyParams(strategies.find((str) => str.name === value)?.defaultParams);
                      }}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {strategies?.map((str) => (
                        <Option key={str.id} value={str.name}>
                          {str.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Candle Size in minutes"
                    name="candleSize"
                    rules={[{ required: true, message: `Please input candleSize!` }]}
                  >
                    <Input type="text" placeholder="candleSize" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="warmup in candles"
                    name="warmupPeriod"
                    rules={[{ required: true, message: `Please input warmupPeriod!` }]}
                  >
                    <Input type="text" placeholder="warmupPeriod" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="useRealMoney" valuePropName="checked">
                    <Checkbox>Use real money!</Checkbox>
                  </Form.Item>
                  <Form.Item name="usePartialBalance" valuePropName="checked">
                    <Checkbox>Use partial balance</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="initial asset balance" name="initialAsset" rules={[{ required: false }]}>
                    <Input type="text" placeholder="initialAsset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="initial currency balance" name="initialCurrency" rules={[{ required: false }]}>
                    <Input type="text" placeholder="initialCurrency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Telegram token" name="telegramToken" rules={[{ required: false }]}>
                    <Input type="text" placeholder="telegramToken" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item label="Telegram password" name="telegramPassword" rules={[{ required: false }]}>
                    <Input type="text" placeholder="telegramPassword" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                {strategyParams &&
                  Object.keys(strategyParams).map((paramKey, i) => (
                    <Col key={i} xs={24} md={12} lg={6}>
                      <Form.Item
                        initialValue={strategyParams[paramKey]}
                        label={paramKey}
                        name={['strategyParams', paramKey]}
                        rules={[{ required: true, message: `Please input ${paramKey}!` }]}
                      >
                        <Input type="text" placeholder={paramKey} />
                      </Form.Item>
                    </Col>
                  ))}
              </Row>

              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Start
                  </Button>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Card>

      <Table style={{ overflow: 'auto' }} columns={columns} loading={isLoadingTradebots} dataSource={tradebots} />
      {trades.length ? (
        <Table
          pagination={{ pageSize: 50 }}
          columns={tradesColumns}
          dataSource={
            trades?.map((trade, i) => ({
              i: i + 1,
              ...trade,
            })) ?? []
          }
        />
      ) : null}
    </div>
  );
};

export default TradebotContainer;
