import React from 'react';
import { PageHeader, Button, Table, Space } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { exchangeCreateRoute, exchangeEditRoute } from '../router/routes';

const columns = [
  {
    title: 'Name',
    render: (to, record) => record.name,
    key: 'name',
  },
  {
    title: 'Slug',
    render: (to, record) => record.slug,
    key: 'slug',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Button key="1" type="primary" href={exchangeEditRoute.replace(':id', record.id)}>
          Edit
        </Button>
        <Button key="2" type="danger" href={exchangeCreateRoute}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const ExchangesContainer = () => {
  const [{ data: storedData }] = useAxiosAuth({
    url: `/exchanges`,
    method: 'GET',
  });

  return (
    <div>
      <PageHeader
        title="Exchange list"
        subTitle="All Exchanges"
        extra={[
          <Button key="1" type="primary" href={exchangeCreateRoute}>
            New
          </Button>,
        ]}
      />
      <Table style={{ overflow: 'auto' }} columns={columns} dataSource={storedData} />
    </div>
  );
};

export default ExchangesContainer;
