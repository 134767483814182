import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Form, Input, Button } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';

const ExchangeFormContainer = () => {
  const params = useParams();
  const isEditing = params.id;
  const [{ data: fetchDataRes, loading: fetchDataLoading }, executeFetchData] = useAxiosAuth(
    {
      url: `/Exchanges${params.id ? `/${params.id}` : ''}`,
      method: isEditing ? 'PATCH' : 'POST',
    },
    { manual: true },
  );
  const [{ data: exchangeData }] = useAxiosAuth(
    {
      url: `/exchanges/${params.id}`,
      method: 'GET',
    },
    { manual: !isEditing },
  );

  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue(exchangeData);
  }, [exchangeData]);

  const onFinish = (values) => {
    console.log('Finish:', values);
    executeFetchData({
      data: {
        ...values,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <PageHeader title="Create Exchange" subTitle="Create Exchange for exchange connection" />
      <Form form={form} name="new_Exchange" onFinish={onFinish}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please input name!' }]}>
          <Input type="name" placeholder="Name" />
        </Form.Item>
        <Form.Item name="slug" rules={[{ required: true, message: 'Please input slug!' }]}>
          <Input type="slug" placeholder="Slug" />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            >
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExchangeFormContainer;
