import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader, Form, Input, Button, Select } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { accountsRoute } from '../router/routes';

const { Option } = Select;

const AccountFormContainer = () => {
  const params = useParams();
  const history = useHistory();
  const isEditing = params.id;
  const [, executeFetchData] = useAxiosAuth(
    {
      url: `/accounts${params.id ? `/${params.id}` : ''}`,
      method: isEditing ? 'PATCH' : 'POST',
    },
    { manual: true },
  );
  const [{ data: accountData }] = useAxiosAuth(
    {
      url: `/accounts/${params.id}`,
      method: 'GET',
    },
    { manual: !isEditing },
  );
  const [{ data: exchanges }] = useAxiosAuth({
    url: `/exchanges`,
    method: 'GET',
  });

  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue(accountData);
  }, [accountData, form]);

  const onFinish = (values) => {
    console.log('Finish:', values);
    executeFetchData({
      data: {
        ...values,
      },
    })
      .then((res) => {
        history.push(accountsRoute);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <PageHeader title="Create account" subTitle="Create account for exchange connection" />
      <Form form={form} name="new_account" onFinish={onFinish}>
        <Form.Item name="exchangeId" rules={[{ required: true, message: 'Please select an exchange!' }]}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select exchange"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {exchanges?.map((exc) => (
              <Option key={exc.id} value={exc.id}>
                {exc.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="name" rules={[{ required: true, message: 'Please input name!' }]}>
          <Input type="name" placeholder="Name" />
        </Form.Item>
        <Form.Item name="description" rules={[{ required: true, message: 'Please input description!' }]}>
          <Input type="description" placeholder="Description" />
        </Form.Item>
        {!isEditing && (
          <>
            <Form.Item name="apiKey" rules={[{ required: !isEditing, message: 'Please input apiKey!' }]}>
              <Input type="apiKey" placeholder="API key" autoComplete="off" />
            </Form.Item>
            <Form.Item name="apiSecret" rules={[{ required: !isEditing, message: 'Please input apiSecret!' }]}>
              <Input type="apiSecret" placeholder="API Secret" autoComplete="off" />
            </Form.Item>
            <Form.Item name="apiPassphrase">
              <Input type="apiPassphrase" placeholder="API Passphrase" autoComplete="off" />
            </Form.Item>
          </>
        )}
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            >
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountFormContainer;
