import React, { useContext } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const LoginContainer = () => {
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const onFinish = (values) => {
    login(values.username, values.password).then((res) => {
      // setSubmitting(false);
      if (res?.data?.data?.login?.access_token) {
        history.push('/');
      } else if (res?.data?.errors?.length > 0) {
      }
    });
    // .catch(() => setSubmitting(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="center">
      <Card title="Login" style={{ width: 300, marginTop: 50 }}>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
    /**<Box
      direction="row"
      justify="center"
      alignContent="center"
      align="center"
      pad="medium"
      background="light-6"
      style={{ height: '100vh' }}
    >
      <Card height="medium" width="medium" background="light-1">
        <CardHeader background="brand" justify="center" pad="medium">
          <Heading margin="none" level={3}>
            LOGIN
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <FormField label="username">
            <TextInput name="username" value={values.username} onBlur={handleBlur} onChange={handleChange} />
          </FormField>
          <FormField label="password">
            <TextInput
              name="password"
              type="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
        </CardBody>
        <CardFooter justify="end" pad={{ horizontal: 'medium', vertical: 'medium' }} background="light-2" height="wide">
          <Button type="submit" icon={<Login />} hoverIndicator label="Login" onClick={handleSubmit} />
        </CardFooter>
      </Card>
    </Box>*/
  );
};

export default LoginContainer;
