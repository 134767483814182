import React, { useState } from 'react';
import * as moment from 'moment';
import { PageHeader, Button, Table, Col, Spin, Statistic, Card, Row, Space, Popconfirm } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth.js';
import { backtestRoute, tradebotsRoute } from '../router/routes';
import { ArrowUpOutlined, ArrowDownOutlined, CheckCircleFilled, SyncOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const BacktestGroupContainer = () => {
  const [selectedBacktest, setSelectedBacktest] = useState(null);
  const [{ data: backtesterRes, loading: isLoadingBacktests }, getBacktests] = useAxiosAuth(
    {
      url: `/backtester`,
      method: 'GET',
    },
    {
      manual: true,
    },
  );

  const [, updateBacktestGroup] = useAxiosAuth(
    {
      url: `/backtest-group/:id`,
      method: 'PATCH',
    },
    {
      manual: true,
    },
  );

  const [, forceRunBacktestGroup] = useAxiosAuth(
    {
      url: `/backtester/force-run-group`,
      method: 'POST',
    },
    { manual: true },
  );

  const [, deleteBacktestGroup] = useAxiosAuth(
    {
      url: `/backtest-group/:id`,
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const [{ data: backtesterGroupRes }, refetchBacktestGroups] = useAxiosAuth({
    url: `/backtest-group`,
    method: 'GET',
  });
  console.log('backtesterRes', backtesterRes);

  const onSelectBacktestGroup = (backtestGroup) => {
    setSelectedBacktest(backtestGroup);
    getBacktests({
      params: {
        backtestGroupId: backtestGroup.id,
      },
    });
  };

  const onStopBacktestGroup = (backtestGroup) => {
    const canStop = window.confirm('Are you sure you want to stop this backtest?');
    if (canStop) {
      updateBacktestGroup({
        url: `${process.env.REACT_APP_SERVER_URL}/backtest-group/${backtestGroup.id}`,
        data: {
          isRunning: false,
        },
      }).then(() => {
        refetchBacktestGroups();
      });
    }
  };

  const onPlayBacktestGroup = (backtestGroup) => {
    const canPlay = window.confirm('Are you sure you want to play this backtest?');
    if (canPlay) {
      updateBacktestGroup({
        url: `${process.env.REACT_APP_SERVER_URL}/backtest-group/${backtestGroup.id}`,
        data: {
          isRunning: true,
        },
      }).then(() => {
        refetchBacktestGroups();
      });
    }
  };

  const onForceRunBacktestGroup = (backtestGroup) => {
    const canForce = window.confirm('Are you sure you want to force run of this backtest?');
    if (canForce) {
      forceRunBacktestGroup({
        data: {
          backtestGroupId: backtestGroup.id,
        },
      })
        .then((res) => {
          refetchBacktestGroups();
        })
        .catch((err) => console.log(err));
    }
  };

  const onDeleteBacktestGroup = (backtestGroup) => {
    deleteBacktestGroup({
      url: `${process.env.REACT_APP_SERVER_URL}/backtest-group/${backtestGroup.id}`,
      data: {
        isRunning: false,
      },
    }).then(() => {
      refetchBacktestGroups();
    });
  };

  const backtestGroupColumns = [
    {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      render: (to, record) => (
        <span>
          {record.isRunning ? <SyncOutlined spin /> : <CheckCircleFilled twoToneColor="#52c41a" />} {record.id}
        </span>
      ),
    },
    {
      title: 'n° Backtest',
      key: 'backtestNumber',
      sorter: (a, b) => a.backtestNumber - b.backtestNumber,
      dataIndex: 'backtestNumber',
    },
    {
      title: 'Strat',
      key: 'strategyName',
      sorter: (a, b) => ('' + a.strategyName).localeCompare(b.strategyName),
      dataIndex: 'strategyName',
    },
    {
      title: 'Pair',
      key: 'asset',
      render: (to, record) => `${record.asset}/${record.currency}`,
    },
    {
      title: 'Start',
      render: (to, record) => moment(record.startDate).format('YYYY-MM-DD HH:mm'),
      key: 'begin',
    },
    {
      title: 'End',
      render: (to, record) => moment(record.endDate).format('YYYY-MM-DD HH:mm'),
      key: 'end',
    },
    {
      title: 'Duration',
      render: (to, record) => {
        const diff = moment(record.endDate).diff(moment(record.startDate));
        return moment.duration(diff).humanize();
      },
      key: 'duration',
    },
    {
      title: 'Actions',
      render: (to, record) => (
        <Space>
          <Button type="default" onClick={() => onSelectBacktestGroup(record)}>
            Select
          </Button>
          <Button disabled={!record.isRunning} danger onClick={() => onStopBacktestGroup(record)}>
            Stop
          </Button>
          {!record.isRunning && (
            <Button disabled={record.isRunning} danger onClick={() => onPlayBacktestGroup(record)}>
              Play
            </Button>
          )}
          {record.isRunning && (
            <Button disabled={!record.timeframeArray} danger onClick={() => onForceRunBacktestGroup(record)}>
              Force run
            </Button>
          )}
          <Popconfirm
            placement="top"
            title="Are you sure to delete this element?"
            onConfirm={() => onDeleteBacktestGroup(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
      key: 'actions',
    },
  ];

  const backtestColumns = [
    {
      title: 'Timeframe',
      key: 'candleSize',
      sorter: (a, b) => a.candleSize - b.candleSize,
      dataIndex: 'candleSize',
    },
    {
      title: 'Warmup',
      key: 'warmupPeriod',
      dataIndex: 'warmupPeriod',
    },
    {
      title: 'Params',
      key: 'strategyParams',
      render: (to, record) => <pre>{JSON.stringify(record.strategyParams, null, 2)}</pre>,
    },
    {
      title: 'n° Trades',
      key: 'tradesNumber',
      sorter: (a, b) => a.tradesNumber - b.tradesNumber,
      dataIndex: 'tradesNumber',
    },
    {
      title: 'Win Trades',
      key: 'winTradesPercentage',
      sorter: (a, b) => a.winTradesPercentage - b.winTradesPercentage,
      render: (to, record) => `${record.winTradesPercentage?.toFixed(2)}%`,
    },
    {
      title: 'YearlyChange',
      key: 'YearlyChange',
      render: (to, record) =>
        record.yearlyChange &&
        record.yearlyChange?.map((yC) => (
          <>
            <span style={{ fontWeight: 'bold' }}>{yC.year}</span>:&nbsp;
            <span
              style={{
                color: yC.change < 0 ? '#cf1322' : '#3f8600',
                fontWeight: yC.marketChange && yC.change >= yC.marketChange ? 'bold' : 'normal',
              }}
            >
              {yC.change.toFixed(2)}% ({yC.marketChange?.toFixed(2) ?? '-'}%)
            </span>
            <br />
          </>
        )),
    },
    {
      title: 'Change',
      key: 'strategyChange',
      sorter: (a, b) => a.strategyChange - b.strategyChange,
      render: (to, record) => {
        const strategyChange = record.strategyChange && record.strategyChange.toFixed(2);
        return <span style={{ color: record.strategyChange < 0 ? '#cf1322' : '#3f8600' }}>{strategyChange}%</span>;
      },
    },
    {
      title: 'Profit - Market',
      key: 'profit',
      sorter: (a, b) => a.strategyChange - b.strategyChange,
      render: (to, record) => {
        const profit = record.strategyChange && (record.strategyChange - record.marketChange).toFixed(2);
        return <span style={{ color: profit < 0 ? '#cf1322' : '#3f8600' }}>{profit}%</span>;
      },
    },
    {
      title: 'Drawdown max',
      key: 'drawdownMaxValue',
      sorter: (a, b) => a.drawdownMaxValue - b.drawdownMaxValue,
      render: (to, record) => {
        return <span>{record.drawdownMaxValue?.toFixed(2) ?? '-'} %</span>;
      },
    },
    {
      title: 'Actions',
      render: (to, record) => {
        const queryString = {
          asset: record.asset,
          currency: record.currency,
          strategyParams: JSON.stringify(record.strategyParams),
          startDate: record.startDate,
          endDate: record.endDate,
          strategyName: record.strategyName,
          exchangeId: record.exchangeId,
          candleSize: record.candleSize,
          warmupPeriod: record.warmupPeriod,
        };
        return (
          <Space>
            <Link to={`${backtestRoute}?${new URLSearchParams(queryString).toString()}`}>
              <Button type="default">Run</Button>
            </Link>
            <Link to={`${tradebotsRoute}?${new URLSearchParams(queryString).toString()}`}>
              <Button type="default">Go Live!</Button>
            </Link>
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Backtest Group"
        subTitle="Completed/Running backtest groups"
        extra={[
          <Button key="1" type="primary" href={backtestRoute}>
            Backtest
          </Button>,
        ]}
      />
      <Table style={{ overflow: 'auto' }} columns={backtestGroupColumns} dataSource={backtesterGroupRes ?? []} />

      {isLoadingBacktests ? (
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Spin size="large" />
        </Col>
      ) : (
        backtesterRes && (
          <>
            <PageHeader className="site-page-header" title="Backtest" subTitle="Backtest results" />
            <Row style={{ justifyItems: 'stretch', marginBottom: 20 }}>
              <Col span={18}>
                <Card style={{ height: '100%' }} bodyStyle={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Statistic title="Strategy" value={backtesterRes[0]?.strategyName ?? ''} />
                  <Statistic title="Processed" value={`${backtesterRes?.length}/${selectedBacktest.backtestNumber}`} />
                  <Statistic
                    title="Market"
                    value={`${backtesterRes[0]?.asset ?? '??'}/${backtesterRes[0]?.currency ?? '??'}`}
                  />
                  <Statistic
                    title="Start date"
                    value={moment(backtesterRes[0]?.startDate).format('YYYY-MM-DD HH:mm')}
                  />
                  <Statistic title="End date" value={moment(backtesterRes[0]?.endDate).format('YYYY-MM-DD HH:mm')} />
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  bodyStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background:
                      backtesterRes[0]?.marketChange <= 0 ? 'rgb(226, 110, 110, 0.5)' : 'rgb(159, 218, 64, 0.5)',
                  }}
                >
                  <Statistic
                    title="Market Change"
                    value={backtesterRes[0]?.marketChange}
                    precision={2}
                    valueStyle={{ color: backtesterRes[0]?.marketChange < 0 ? '#cf1322' : '#3f8600' }}
                    prefix={backtesterRes[0]?.marketChange < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>
            <Table
              style={{ overflow: 'auto' }}
              pagination={{ pageSize: 50 }}
              columns={backtestColumns}
              dataSource={backtesterRes ?? []}
            />
          </>
        )
      )}
    </div>
  );
};

export default BacktestGroupContainer;
