import React, { useState, createContext } from 'react';
import axios from 'axios';
import useLocalStorage from '../hooks/useLocalStorage';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [loginData, setLoginData, removeLoginData] = useLocalStorage('loginData', null);
  const [errorMessage, setErrorMessage] = useState(null);
  const isLoggedIn = () => loginData !== null && loginData?.access_token !== null;

  const login = (username, password) => {
    setErrorMessage(null);
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
        username,
        password,
      })
      .then((res) => {
        const response = res && res.data;
        console.log(res);
        if (response.access_token) {
          setLoginData(response);
        } else if (response?.errors) {
          setErrorMessage(response.errors);
        }
        return res;
      })
      .catch((error) => {
        console.log('AuthProvider -> error', error.response);
        setErrorMessage(error.response.data.message);
        return error;
      });
  };

  const logout = () => {
    removeLoginData();
  };

  return (
    <AuthContext.Provider
      value={{
        loginData,
        login,
        logout,
        isLoggedIn,
        errorMessage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
