import React from 'react';
import { PageHeader, Button, Table, Space } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { walletCreateRoute, walletEditRoute } from '../router/routes';

const WalletsContainer = () => {
  const [{ data: storedData }, refetchWallets] = useAxiosAuth({
    url: `/wallets`,
    method: 'GET',
  });

  const [, deleteWallet] = useAxiosAuth(
    {
      method: 'DELETE',
    },
    { manual: true },
  );

  const onDeleteWallet = (id) => {
    deleteWallet({
      url: `${process.env.REACT_APP_SERVER_URL}/wallets/${id}`,
    })
      .then((res) => {
        refetchWallets();
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  const columns = [
    {
      title: 'Name',
      render: (to, record) => record.name,
      key: 'name',
    },
    {
      title: 'RPC',
      render: (to, record) => record.rpc,
      key: 'rps',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="danger" onClick={() => onDeleteWallet(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Wallet list"
        subTitle="All personal Wallets"
        extra={[
          <Button key="1" type="primary" href={walletCreateRoute}>
            New
          </Button>,
        ]}
      />
      <Table style={{ overflow: 'auto' }} columns={columns} dataSource={storedData} />
    </div>
  );
};

export default WalletsContainer;
