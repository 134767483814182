import { useContext } from 'react';
import useAxios from 'axios-hooks';
import { AuthContext } from '../context/AuthContext';

const useAxiosAuth = ({ url, ...data }, others) => {
  const { loginData, logout } = useContext(AuthContext);
  const [{ error, loading, data: dataRes }, execute] = useAxios(
    {
      url: `${process.env.REACT_APP_SERVER_URL}${url}`,
      headers: {
        Authorization: `Bearer ${loginData.access_token.access_token}`,
      },
      ...data,
    },
    others,
  );
  if (JSON.parse(JSON.stringify(error))?.message === 'Request failed with status code 401') {
    logout();
  }
  return [{ error, loading, data: dataRes }, execute];
};

export default useAxiosAuth;
