import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader, Form, Input, Button, Select } from 'antd';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { walletsRoute } from '../router/routes';

const { Option } = Select;

const WalletFormContainer = () => {
  const params = useParams();
  const history = useHistory();
  const isEditing = params.id;
  const [, executeFetchData] = useAxiosAuth(
    {
      url: `/wallets${params.id ? `/${params.id}` : ''}`,
      method: isEditing ? 'PATCH' : 'POST',
    },
    { manual: true },
  );
  const [{ data: walletData }] = useAxiosAuth(
    {
      url: `/wallets/${params.id}`,
      method: 'GET',
    },
    { manual: !isEditing },
  );
  const [{ data: wallets }] = useAxiosAuth({
    url: `/wallets`,
    method: 'GET',
  });

  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue(walletData);
  }, [walletData]);

  const onFinish = (values) => {
    console.log('Finish:', values);
    executeFetchData({
      data: {
        ...values,
      },
    })
      .then((res) => {
        history.push(walletsRoute);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <PageHeader title="Create wallet" subTitle="Create wallet for exchange connection" />
      <Form form={form} name="new_wallet" onFinish={onFinish}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please input name!' }]}>
          <Input type="name" placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="encryptedPrivateKey"
          rules={[{ required: !isEditing, message: 'Please input encryptedPrivateKey!' }]}
        >
          <Input type="password" placeholder="Private key" autoComplete="off" />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            >
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default WalletFormContainer;
