const loginRoute = '/login';

const dashboardRoute = '/';
const personalWalletsRoute = '/personal-wallets';
const walletsRoute = '/wallets';
const walletCreateRoute = '/wallet-create';
const walletEditRoute = '/wallet-edit/:id';
const accountsRoute = '/accounts';
const accountCreateRoute = '/account-create';
const accountEditRoute = '/account-edit/:id';
const exchangesRoute = '/exchanges';
const exchangeCreateRoute = '/exchange-create';
const exchangeEditRoute = '/exchange-edit/:id';
const accountBalancesRoute = '/account-balances';
const storedDataRoute = '/stored-data';
const backtestRoute = '/backtest';
const backtestGroupRoute = '/backtest-group';
const tradebotsRoute = '/tradebots';

export {
  loginRoute,
  dashboardRoute,
  walletsRoute,
  walletCreateRoute,
  walletEditRoute,
  storedDataRoute,
  accountBalancesRoute,
  accountsRoute,
  accountCreateRoute,
  accountEditRoute,
  exchangesRoute,
  exchangeCreateRoute,
  exchangeEditRoute,
  backtestRoute,
  backtestGroupRoute,
  tradebotsRoute,
  personalWalletsRoute,
};
