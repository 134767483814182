import React from 'react';
import AppLayout from '../components/layout/AppLayout';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from '../context/AuthContext';
import LoginContainer from '../containers/LoginContainer';
import StoredDataContainer from '../containers/StoredDataContainer';
import AccountBalancesContainer from '../containers/AccountBalancesContainer';
import AccountsContainer from '../containers/AccountsContainer';
import WalletsContainer from '../containers/WalletsContainer';
import PersonalWalletsContainer from '../containers/PersonalWalletsContainer';
import AccountFormContainer from '../containers/AccountFormContainer';
import WalletFormContainer from '../containers/WalletFormContainer';
import ExchangesContainer from '../containers/ExchangesContainer';
import ExchangeFormContainer from '../containers/ExchangeFormContainer';
import BacktestContainer from '../containers/BacktestContainer';
import BacktestGroupContainer from '../containers/BacktestGroupContainer';
import TradebotContainer from '../containers/TradebotContainer';
import {
  dashboardRoute,
  accountBalancesRoute,
  storedDataRoute,
  loginRoute,
  accountsRoute,
  accountCreateRoute,
  accountEditRoute,
  exchangesRoute,
  exchangeCreateRoute,
  exchangeEditRoute,
  backtestRoute,
  backtestGroupRoute,
  tradebotsRoute,
  walletsRoute,
  walletCreateRoute,
  personalWalletsRoute,
} from './routes';

const AppRouter = () => {
  return (
    <AuthConsumer>
      {({ isLoggedIn }) =>
        isLoggedIn() ? (
          <Router>
            <AppLayout>
              <Switch>
                <Route exact path={dashboardRoute}>
                  dashboard
                </Route>
                <Route exact path={accountBalancesRoute} component={AccountBalancesContainer} />
                <Route exact path={storedDataRoute} component={StoredDataContainer} />
                <Route exact path={accountsRoute} component={AccountsContainer} />
                <Route exact path={accountCreateRoute} component={AccountFormContainer} />
                <Route exact path={accountEditRoute} component={AccountFormContainer} />
                <Route exact path={walletsRoute} component={WalletsContainer} />
                <Route exact path={personalWalletsRoute} component={PersonalWalletsContainer} />
                <Route exact path={walletCreateRoute} component={WalletFormContainer} />
                <Route exact path={exchangesRoute} component={ExchangesContainer} />
                <Route exact path={exchangeCreateRoute} component={ExchangeFormContainer} />
                <Route exact path={exchangeEditRoute} component={ExchangeFormContainer} />
                <Route exact path={backtestRoute} component={BacktestContainer} />
                <Route exact path={backtestGroupRoute} component={BacktestGroupContainer} />
                <Route exact path={tradebotsRoute} component={TradebotContainer} />
                <Redirect to={dashboardRoute} />
              </Switch>
            </AppLayout>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route exact path={loginRoute} component={LoginContainer} />
              <Redirect to={loginRoute} />
            </Switch>
          </Router>
        )
      }
    </AuthConsumer>
  );
};

export default AppRouter;
