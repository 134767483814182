import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { DashboardOutlined, WalletOutlined } from '@ant-design/icons';
import { FaUniversity, FaDatabase, FaMoneyBillWave, FaChartLine, FaPowerOff, FaRobot } from 'react-icons/fa';

import {
  dashboardRoute,
  accountBalancesRoute,
  storedDataRoute,
  accountsRoute,
  walletsRoute,
  exchangesRoute,
  backtestRoute,
  backtestGroupRoute,
  tradebotsRoute,
  personalWalletsRoute,
} from '../../router/routes';
import { AuthContext } from '../../context/AuthContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import useViewport from '../../hooks/useViewport';

const { Header, Sider, Content } = Layout;

const AppLayout = ({ children }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useLocalStorage('sidebarOpen', false);
  const [screenWidth] = useViewport();
  const { logout } = useContext(AuthContext);

  const MenuItem = (props) => (
    <Menu.Item onClick={() => (screenWidth <= 1024 ? setCollapsed(true) : () => {})} {...props}>
      {props.children}
    </Menu.Item>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} collapsedWidth={0} onCollapse={setCollapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location?.pathname]}>
          <MenuItem key={dashboardRoute} icon={<DashboardOutlined />}>
            <Link to={dashboardRoute} title="Dashboard">
              Dashboard
            </Link>
          </MenuItem>
          <MenuItem key={exchangesRoute} icon={<FaUniversity />}>
            <Link to={exchangesRoute} title="Exchanges">
              Exchanges
            </Link>
          </MenuItem>
          <MenuItem key={accountsRoute} icon={<WalletOutlined />}>
            <Link to={accountsRoute} title="Accounts">
              Accounts
            </Link>
          </MenuItem>
          <Menu.SubMenu
            key={`${walletsRoute}-e`}
            icon={<WalletOutlined style={{ marginRight: 10 }} />}
            title={collapsed ? null : 'Wallets'}
          >
            <MenuItem key={walletsRoute}>
              <Link to={walletsRoute} title="Trading wallets">
                Trading wallets
              </Link>
            </MenuItem>
            <MenuItem key={personalWalletsRoute}>
              <Link to={personalWalletsRoute} title="Personal Wallets">
                Personal Wallets
              </Link>
            </MenuItem>
          </Menu.SubMenu>
          <MenuItem key={accountBalancesRoute} icon={<FaMoneyBillWave />}>
            <Link to={accountBalancesRoute} title="Balances">
              Balances
            </Link>
          </MenuItem>
          <MenuItem key={storedDataRoute} icon={<FaDatabase />}>
            <Link to={storedDataRoute} title="Stored Data">
              Stored Data
            </Link>
          </MenuItem>
          <Menu.SubMenu
            key={`${backtestRoute}-e`}
            icon={<FaChartLine style={{ marginRight: 10 }} />}
            title={collapsed ? null : 'Backtest'}
          >
            <MenuItem key={backtestRoute}>
              <Link to={backtestRoute} title="Backtest">
                New Backtest
              </Link>
            </MenuItem>
            <MenuItem key={backtestGroupRoute}>
              <Link to={backtestGroupRoute} title="Backtest Groups">
                Backtest Groups
              </Link>
            </MenuItem>
          </Menu.SubMenu>
          <MenuItem key={tradebotsRoute} icon={<FaRobot />}>
            <Link to={tradebotsRoute} title="Tradebots">
              Tradebots
            </Link>
          </MenuItem>
          <MenuItem key="9999" icon={<FaPowerOff />} onClick={logout}>
            Logout
          </MenuItem>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1, width: '100%' }}></Header>
        <Content className="site-layout-background" style={{ padding: '20px', marginTop: 64, overflow: 'auto' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
