import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  PageHeader,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Table,
  Card,
  Statistic,
  Row,
  Col,
  Spin,
  Tabs,
} from 'antd';
import LineChart from '../components/charts/LineChartD3';
import { ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { backtestGroupRoute } from '../router/routes';
import {
  LineChart as RLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { map } from 'lodash';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const { Option } = Select;
const { TabPane } = Tabs;

const BacktestContainer = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const exchangeId = Number(params.get('exchangeId')) || null;
  const asset = params.get('asset') || null;
  const currency = params.get('currency') || null;
  const strategyName = params.get('strategyName') || null;
  const candleSize = Number(params.get('candleSize')) || null;
  const warmupPeriod = Number(params.get('warmupPeriod')) || null;
  const strategyParamsQuery = JSON.parse(params.get('strategyParams')) || null;
  const startDate = moment(params.get('startDate')) || null;
  const endDate = moment(params.get('endDate')) || null;

  const initialValues = {
    exchangeId,
    strategyName,
    startDate,
    endDate,
    asset,
    currency,
    candleSize,
    warmupPeriod,
    assetBalance: 0,
    currencyBalance: 1000,
    feeMode: 'MAKER',
    makerFee: null,
    takerFee: null,
    ...strategyParamsQuery,
  };

  const [form] = Form.useForm();
  const [formGroup] = Form.useForm();
  const [, forceUpdate] = useState();
  const [strategyParams, setStrategyParams] = useState(null);

  const [{ data: storedData, loading: isLoadingData }] = useAxiosAuth({
    url: `/historical-data/get-historical-data`,
    method: 'GET',
  });

  const [{ data: strategies }] = useAxiosAuth({
    url: `/backtester/strategies`,
    method: 'GET',
  });

  const [{ data: exchanges }] = useAxiosAuth({
    url: `/exchanges`,
    method: 'GET',
  });

  const [{ data: backtestResult, loading: isLoadingBacktest }, runBacktest] = useAxiosAuth(
    {
      url: `/backtester/run`,
      method: 'POST',
    },
    { manual: true },
  );

  const [, runBacktestGroup] = useAxiosAuth(
    {
      url: `/backtester/run-group`,
      method: 'POST',
    },
    { manual: true },
  );

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (exchanges?.length) {
      const selectedExc = exchanges?.find((e) => e.id === Number(initialValues.exchangeId));
      form.setFieldsValue({
        ...initialValues,
        exchange: selectedExc?.name,
      });
      if (strategyName) {
        setStrategyParams(strategyParamsQuery);
      }
    }
  }, [exchanges]);

  const onFinish = (values) => {
    console.log('onFinish -> values', values);
    runBacktest({
      data: {
        ...values,
        assetBalance: Number(values.assetBalance),
        currencyBalance: Number(values.currencyBalance),
        slippage: Number(values.slippage),
        candleSize: Number(values.candleSize),
        warmupPeriod: Number(values.warmupPeriod),
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
        const data = err.response?.data;
        alert(`Error: ${data?.error} \n${JSON.stringify(data?.message) ?? ''}`);
      });
  };

  const onFinishGroup = (values) => {
    const multipleParams = {};
    Object.keys(values.strategyParams).forEach((key) => {
      if (!isNaN(values.strategyParams[key].from)) {
        multipleParams[key] = _.range(
          values.strategyParams[key].from,
          Number(values.strategyParams[key].to) + 1,
          values.strategyParams[key].step,
        );
      } else {
        multipleParams[key] = [values.strategyParams[key].from];
      }
    });
    runBacktestGroup({
      data: {
        ...values,
        strategyParams: multipleParams,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
    console.log(values.strategyParams);
    console.log(multipleParams);
  };

  const onSelectDataset = (dataset) => {
    form.setFieldsValue({
      ...dataset,
      exchange: dataset.exchange?.name,
      startDate: moment(dataset.begin),
      endDate: moment(dataset.end),
    });
    formGroup.setFieldsValue({
      ...dataset,
      exchange: dataset.exchange?.name,
      startDate: moment(dataset.begin),
      endDate: moment(dataset.end),
    });
  };

  const columns = [
    {
      title: 'Exchange',
      render: (to, record) => record.exchange.name,
      key: 'exchange.name',
    },
    {
      title: 'Asset',
      key: 'asset',
      dataIndex: 'asset',
    },
    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency',
    },
    {
      title: 'Start',
      render: (to, record) => moment(record.begin).format('YYYY-MM-DD HH:mm'),
      key: 'begin',
    },
    {
      title: 'End',
      render: (to, record) => moment(record.end).format('YYYY-MM-DD HH:mm'),
      key: 'end',
    },
    {
      title: 'Duration',
      render: (to, record) => {
        const diff = moment(record.end).diff(moment(record.begin));
        return moment.duration(diff).humanize();
      },
      key: 'duration',
    },
    {
      title: 'Select',
      render: (to, record) => (
        <Button type="default" onClick={() => onSelectDataset(record)}>
          Select
        </Button>
      ),
      key: 'select',
    },
  ];

  const backtestColumns = [
    {
      title: 'n°',
      sorter: (a, b) => a.i - b.i,
      render: (to, record, i) => record.i,
      key: 'i',
    },
    {
      title: 'Buy price',
      sorter: (a, b) => a.buyPrice - b.buyPrice,
      render: (to, record) => record.buyPrice && record.buyPrice.toFixed(2),
      key: 'buyPrice',
    },
    {
      title: 'Sell price',
      key: 'sellPrice',
      sorter: (a, b) => a.sellPrice - b.sellPrice,
      render: (to, record) => record.sellPrice && record.sellPrice.toFixed(2),
    },
    {
      title: 'buyDate',
      sorter: (a, b) => a.buyDate - b.buyDate,
      render: (to, record) => (record.buyDate ? moment(record.buyDate).format('YYYY-MM-DD HH:mm') : '-'),
      key: 'buyDate',
    },
    {
      title: 'sellDate',
      sorter: (a, b) => a.sellDate - b.sellDate,
      render: (to, record) => (record.sellDate ? moment(record.sellDate).format('YYYY-MM-DD HH:mm') : '-'),
      key: 'sellDate',
    },

    {
      title: 'Entry balance',
      render: (to, record) => record.entryBalance && record.entryBalance.toFixed(2),
      key: 'entryBalance',
    },
    {
      title: 'Exit balance',
      key: 'exitBalance',
      render: (to, record) => record.exitBalance && record.exitBalance.toFixed(2),
    },
    {
      title: 'comment',
      sorter: (a, b) => a.comment - b.comment,
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'change',
      sorter: (a, b) => a.change - b.change,
      render: (to, record) => (
        <span style={{ color: record.change < 0 ? '#cf1322' : '#3f8600' }}>
          {Number(record?.change).toFixed(2) + '%' ?? '--'}
        </span>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Stored data"
        subTitle="Stored asset/currency data from the exchanges"
        extra={[
          <Button key="1" type="primary" href={backtestGroupRoute}>
            Backtest batch results
          </Button>,
        ]}
      />
      <Table style={{ overflow: 'auto' }} columns={columns} loading={isLoadingData} dataSource={storedData} />
      <Card title="New backtest" style={{ marginTop: 24 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Single" key="1">
            <Form form={form} name="newBacktest" layout="vertical" initialValues={initialValues} onFinish={onFinish}>
              <Row gutter={24}>
                <Form.Item name="exchangeId">
                  <Input hidden disabled type="exchangeId" />
                </Form.Item>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="exchange"
                    label="Exchange"
                    rules={[{ required: true, message: 'Please select an exchange!' }]}
                  >
                    <Input disabled type="text" placeholder="Exchange" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="asset" label="Asset" rules={[{ required: true, message: 'Please input asset!' }]}>
                    <Input disabled type="text" placeholder="Asset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please input currency!' }]}
                  >
                    <Input disabled type="text" placeholder="Currency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Strategy"
                    name="strategyName"
                    rules={[{ required: true, message: 'Please select a strategy!' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select strategy"
                      optionFilterProp="children"
                      onChange={(value) => {
                        setStrategyParams(strategies.find((str) => str.name === value)?.defaultParams);
                      }}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {strategies?.map((str) => (
                        <Option key={str.id} value={str.name}>
                          {str.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Start date"
                    name="startDate"
                    rules={[{ required: true, message: 'Please input startDate!' }]}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="End date"
                    name="endDate"
                    rules={[{ required: true, message: 'Please input endDate!' }]}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label={`Candle Size in minutes ()`}
                    name="candleSize"
                    rules={[{ required: true, message: `Please input candleSize!` }]}
                  >
                    <Input type="text" placeholder="candleSize" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="warmup in candles"
                    name="warmupPeriod"
                    rules={[{ required: true, message: `Please input warmupPeriod!` }]}
                  >
                    <Input type="text" placeholder="warmupPeriod" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Asset initial balance"
                    name="assetBalance"
                    rules={[{ required: true, message: `Please input assetBalance!` }]}
                  >
                    <Input type="number" placeholder="Asset initial balance" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Currency initial balance"
                    name="currencyBalance"
                    rules={[{ required: true, message: `Please input currencyBalance!` }]}
                  >
                    <Input type="number" placeholder="Currency initial balance" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Form.Item label="Slippage % sell" name="slippage">
                    <Input type="number" step="0.1" placeholder="slippage" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Form.Item
                    label="Fee mode"
                    name="feeMode"
                    rules={[{ required: true, message: 'Please select a feeMode!' }]}
                  >
                    <Select placeholder="Fee mode">
                      <Option value="MAKER">MAKER</Option>
                      <Option value="TAKER">TAKER</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Form.Item label="Maker fee %" name="makerFee">
                    <Input type="number" step="0.1" placeholder="makerFee" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Form.Item label="Taker fee %" name="takerFee">
                    <Input type="number" step="0.1" placeholder="takerFee" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                {strategyParams &&
                  Object.keys(strategyParams).map((paramKey, i) => (
                    <Col key={i} xs={24} md={12} lg={6}>
                      <Form.Item
                        initialValue={strategyParams[paramKey]}
                        label={paramKey}
                        name={['strategyParams', paramKey]}
                        rules={[{ required: true, message: `Please input ${paramKey}!` }]}
                      >
                        <Input type="text" placeholder={paramKey} />
                      </Form.Item>
                    </Col>
                  ))}
              </Row>

              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Start
                  </Button>
                )}
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Group" key="2">
            <Form
              form={formGroup}
              name="newBacktestGroup"
              layout="vertical"
              initialValues={initialValues}
              onFinish={onFinishGroup}
            >
              <Row gutter={24}>
                <Form.Item name="exchangeId">
                  <Input hidden disabled type="exchangeId" />
                </Form.Item>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="exchange"
                    label="Exchange"
                    rules={[{ required: true, message: 'Please select an exchange!' }]}
                  >
                    <Input disabled type="text" placeholder="Exchnage" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item name="asset" label="Asset" rules={[{ required: true, message: 'Please input asset!' }]}>
                    <Input disabled type="text" placeholder="Asset" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please input currency!' }]}
                  >
                    <Input disabled type="text" placeholder="Currency" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Strategy"
                    name="strategyName"
                    rules={[{ required: true, message: 'Please select a strategy!' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select strategy"
                      optionFilterProp="children"
                      onChange={(value) => {
                        setStrategyParams(strategies.find((str) => str.name === value)?.defaultParams);
                      }}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {strategies?.map((str, is) => (
                        <Option key={is} value={str.name}>
                          {str.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Start date"
                    name="startDate"
                    rules={[{ required: true, message: 'Please input startDate!' }]}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    label="End date"
                    name="endDate"
                    rules={[{ required: true, message: 'Please input endDate!' }]}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                {strategyParams &&
                  ['from', 'to', 'step'].map((keyType, i) => (
                    /** _.range(0, 20, 5); => [0, 5, 10, 15] */
                    <Col key={i} span={8}>
                      {Object.keys(strategyParams).map((paramKey) => (
                        <Form.Item
                          key={paramKey}
                          label={`${paramKey} ${keyType}`}
                          name={['strategyParams', paramKey, keyType]}
                          rules={[{ required: true, message: `Please input ${paramKey} ${keyType}!` }]}
                        >
                          <Input type="text" placeholder={`${paramKey} ${keyType}`} />
                        </Form.Item>
                      ))}
                    </Col>
                  ))}
              </Row>
              <Form.List name="timeframeArray">
                {(fields, { add, remove }) => (
                  <Row gutter={24}>
                    {fields.map((field) => (
                      <React.Fragment key={field.key}>
                        <Col span={10} key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...field}
                            name={[field.name, 'candleSize']}
                            fieldKey={[field.fieldKey, 'candleSize']}
                            rules={[{ required: true, message: 'Missing candle size value' }]}
                          >
                            <Input type="number" placeholder="Candle Size" />
                          </Form.Item>
                        </Col>
                        <Col span={10} key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...field}
                            name={[field.name, 'warmupPeriod']}
                            fieldKey={[field.fieldKey, 'warmupPeriod']}
                            rules={[{ required: true, message: 'Missing warmupPeriod value' }]}
                          >
                            <Input type="number" placeholder="Warmup Period" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Col>
                      </React.Fragment>
                    ))}
                    <Col span={4}>
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add field
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>

              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={formGroup.getFieldsError().filter(({ errors }) => errors.length).length}
                  >
                    Start
                  </Button>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Card>

      {isLoadingBacktest ? (
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Spin size="large" />
        </Col>
      ) : (
        backtestResult && (
          <>
            <PageHeader className="site-page-header" title="Backtest result" subTitle="Backtest result with trades" />
            <Row style={{ justifyItems: 'stretch', marginBottom: 20 }}>
              <Col span={14}>
                <Card style={{ height: '100%' }} bodyStyle={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Statistic title="Total trades" value={backtestResult.trades?.length * 2} />
                  <Statistic title="Closed trades" value={backtestResult.trades?.length} />
                  <Statistic title="Win" value={backtestResult.winTradesNumber ?? '/'} />
                  <Statistic title="Win %" value={backtestResult.winTradesPercentage?.toFixed(2) + '%' ?? '/'} />
                  <Statistic title="Start value" value={backtestResult.initialValue?.toFixed(2)} />
                  <Statistic title="End value" value={backtestResult.finalTotalBalance?.toFixed(2)} />
                </Card>
              </Col>
              <Col span={10}>
                <Card
                  bodyStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background:
                      backtestResult.marketChange >= backtestResult.strategyChange
                        ? 'rgb(226, 110, 110, 0.5)'
                        : 'rgb(159, 218, 64, 0.5)',
                  }}
                >
                  <Statistic
                    title="Market Change"
                    value={backtestResult?.marketChange}
                    precision={2}
                    valueStyle={{ color: backtestResult.marketChange < 0 ? '#cf1322' : '#3f8600' }}
                    prefix={backtestResult.marketChange < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                    suffix="%"
                  />
                  <Statistic
                    title="Strategy Change"
                    value={backtestResult?.strategyChange}
                    precision={2}
                    valueStyle={{ color: backtestResult.strategyChange < 0 ? '#cf1322' : '#3f8600' }}
                    prefix={backtestResult.strategyChange < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>
            <Row style={{ justifyItems: 'stretch', marginBottom: 20 }}>
              <Col span={24}>
                <Card style={{ height: '100%' }} bodyStyle={{ display: 'flex', justifyContent: 'space-between' }}>
                  {backtestResult.yearlyChange?.map((yC) => (
                    <Statistic
                      title={`${yC.year} (${yC.marketChange?.toFixed(2)} %)`}
                      suffix="%"
                      style={{}}
                      prefix={yC.change < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                      value={yC.change.toFixed(2)}
                      valueStyle={{
                        color: yC.change < 0 ? '#cf1322' : '#3f8600',
                        fontWeight: yC.marketChange && yC.change < yC.marketChange ? 'normal' : 'bold',
                      }}
                    />
                  ))}
                </Card>
              </Col>
            </Row>
            <LineChart
              _height={600}
              _data={backtestResult.candlesClosePrices ?? []}
              _trades={backtestResult.trades ?? []}
            />
            <Row>
              <PageHeader
                className="site-page-header"
                title={`Equity line - max drawdown: ${backtestResult?.drawdownMax.value?.toFixed(2)}% (${moment(
                  backtestResult?.drawdownMax.start,
                ).format('DD/MM/YYYY')} -> ${moment(backtestResult?.drawdownMax.end).format('DD/MM/YYYY')})`}
              />
              <ResponsiveContainer width="100%" height={400}>
                <RLineChart
                  width={1300}
                  height={300}
                  data={backtestResult?.equityLinePoints?.map((p) => ({
                    balance: Number(p.value?.toFixed(2)),
                    name: moment(p.timestamp).format('YYYY-MM-DD'),
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="balance" stroke="#8884d8" dot={false} />
                </RLineChart>
              </ResponsiveContainer>
            </Row>

            <Table
              pagination={{ pageSize: 50 }}
              columns={backtestColumns}
              dataSource={
                backtestResult?.trades?.map((trade, i) => ({
                  i: i + 1,
                  ...trade,
                })) ?? []
              }
            />
          </>
        )
      )}
    </div>
  );
};

export default BacktestContainer;
